import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { analyticsPageViewEvent } from 'analytics/actions'
import styled from 'styled-components'
import { endsWith, findIndex, flow, get, isEmpty, isEqual, isNil, map, negate, reject, some, filter } from 'lodash/fp'
import { compose, getContext, lifecycle, withProps } from 'recompose'
import PropTypes from 'prop-types'

import { getToggles } from 'quick-quote/feature-toggles'
import { updateOptyStageEApp, updateOptyStageKyc } from 'quick-quote/opty/actions'

import {
  fetchSignature,
  hideDialog,
  hideLoading,
  showDialog,
  verifyPolicy,
  comfirmSubmittedPolicy,
  saveAppData,
  setDopaRequestFailedModal,
  submittedPolicy,
  setAppValue,
  resetEDAViewFormOnly,
  unsetEDACheckStatus,
  setConfirmLuckyNumberModal,
} from 'e-submission/apps/actions'
import {
  getCurrentAppStatus,
  getCurrentApp,
  getAppProgress,
  getPages,
  isAllDocumentSigned,
  isCertifyByAgent,
  getCurrentAppId,
  getStateSaleForm,
} from 'e-submission/apps/selectors'
import { when } from 'e-submission/utils'
import FormEngine from 'e-submission/components/FormEngine'

import FormValidation from './FormValidation'
import InsuredInfoCard from 'e-submission/components/Card/InsuredInfoCard'
import {
  getInsuredDopaResult,
  getPayerDopaResult,
  getSkippedInsuredEkyc,
  getSkippedPayerEkyc,
  getInsuredDopaRequestFailed,
  getPayerDopaRequestFailed,
  getSelectedLuckyNumber,
  isLuckyNumber,
  getLuckyNumberStatus,
} from 'e-submission/domain/data-model/form/selectors'
import { openEkycPage } from 'quick-quote/remote-selling/actions'
import {
  getRemoteSellingState,
  isRemoteSellingPaymentCompleted,
  isRemoteSelling as isOldRemoteSelling,
} from 'e-submission/domain/data-model/form/selectors'

const AppForm = styled(({ className, pages, sections, match, ...props }) => (
  <div id={`app-form-${props.currentPageId}`} className={className}>
    <InsuredInfoCard />
    {endsWith('/form/signature', match.url) ? <FormValidation /> : null}
    <FormEngine sections={sections} {...props} />
  </div>
))`
  flex: 1 1 auto;
  padding-top: 15px;
  margin: 0 auto;

  &#app-form-KYC {
    .validation-message {
      margin-top: 10px;
    }
  }

  ${`
    &#app-form-submit {
      padding-top: 30px;
    }
  `};
`

const isSubmitPage = isEqual('submit')
const isRemoteSignaturePage = isEqual('remote-signature')
const isEkycPage = isEqual('ekyc')

const mapStateToProps = (state, props) => {
  const pages = getPages(state)
  const appStatus = getCurrentAppStatus(state)
  const progress = getAppProgress(state)
  const currentPageId = props.match.params.pageId
  const currentPageIndex = findIndex(['id', currentPageId], pages)
  const currentPage = get(currentPageIndex, pages)
  const sections = get('sections', currentPage ? currentPage : [])
  const size = get('size', currentPage ? currentPage : 0)
  const currentApp = getCurrentApp(state)
  const isRemoteSelling = currentApp?.selectedRemoteSelling

  let canGotoNextPage = false
  if (currentPage && currentPage?.lastPage) {
    canGotoNextPage = isAllDocumentSigned(state)
  }

  const canSign = currentPage?.isDocumentPage ? isCertifyByAgent(state) : false

  const app = getCurrentApp(state)
  const isSkippedInsuredEkyc = getSkippedInsuredEkyc(app)
  const isSkippedPayerEkyc = getSkippedPayerEkyc(app)
  const insuredDopaVerified = getInsuredDopaResult(app)
  const payerDopaVerified = getPayerDopaResult(app)
  const insuredDopaRequestFailed = getInsuredDopaRequestFailed(app)
  const payerDopaRequestFailed = getPayerDopaRequestFailed(app)
  const appId = getCurrentAppId(state)
  const startSaleForm = getStateSaleForm(state)
  const selectedLuckyNumber = getSelectedLuckyNumber(app)
  const isShowLuckyNumber = isLuckyNumber(app)
  const luckyNumberStatus = getLuckyNumberStatus(app)

  return {
    appStatus,
    canGotoNextPage,
    pages,
    sections,
    progress,
    currentPageId,
    currentPageIndex: Math.max(0, currentPageIndex),
    size,
    canSign,
    isSkippedInsuredEkyc,
    isSkippedPayerEkyc,
    insuredDopaVerified,
    payerDopaVerified,
    insuredDopaRequestFailed,
    payerDopaRequestFailed,
    isRemoteSelling,
    currentApp,
    appId,
    startSaleForm,
    selectedLuckyNumber,
    isShowLuckyNumber,
    luckyNumberStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideLoading: () => {
      dispatch(hideLoading())
    },
    showDialog: (dialog) => {
      dispatch(showDialog(dialog))
    },
    hideDialog: (dialog) => {
      dispatch(hideDialog(dialog))
    },
    verifyPolicy: (event) => {
      event.preventDefault()
      dispatch(verifyPolicy())
    },
    confirmSubmitted: (event) => {
      event.preventDefault()
      dispatch(comfirmSubmittedPolicy())
    },
    submittedPolicy: (event) => {
      dispatch(submittedPolicy())
    },
    fetchSignature: () => {
      dispatch(fetchSignature())
    },
    openDf2fEkycPage: (navProps) => {
      dispatch(openEkycPage(navProps))
    },
    dispatchAnalyticsPageViewEvent: bindActionCreators(analyticsPageViewEvent, dispatch),
    dispatchSavingAppOnPageNav: bindActionCreators(saveAppData, dispatch),
    openInsuredDopaRequestFailedModal: (insuredOrPayer) => {
      dispatch(setDopaRequestFailedModal(true, 'insured'))
    },
    openPayerDopaRequestFailedModal: (insuredOrPayer) => {
      dispatch(setDopaRequestFailedModal(true, 'payer'))
    },
    dispatchUpdateOptyStageKyc: bindActionCreators(updateOptyStageKyc, dispatch),
    dispatchUppdateOptyStageEApp: bindActionCreators(updateOptyStageEApp, dispatch),
    setTypeDA: (typeDA) => {
      dispatch(setAppValue('ATP.typeDA', typeDA))
    },
    setDefaultBankForEDA: () => {
      dispatch(setAppValue('ATP.bank', { text: 'ธนาคารกรุงไทย', monthlyCollect: 1, value: '006' }))
    },
    clearUIFlagEDAViewFormOnly: () => {
      dispatch(resetEDAViewFormOnly())
    },
    clearEdaStatusOptions: () => {
      dispatch(unsetEDACheckStatus())
    },
    setConfirmLuckyNumberModal: (val) => {
      dispatch(setConfirmLuckyNumberModal(val))
    },
  }
}

const mergeProps = (stateProps, actionProps, ownProps) => {
  const pages = stateProps.pages
  const nextPageIndex = stateProps.currentPageIndex + 1
  const currentPage = get(stateProps.currentPageIndex, pages)
  const nextPage = get(nextPageIndex, pages)
  const { isRemoteSelling, currentApp: app } = stateProps
  const shouldDisable = (id, app) => {
    switch (id) {
      case 'video-consent':
        return true
      default:
        return false
    }
  }
  let nextButtonProps = {}
  if (!isNil(nextPage)) {
    if (currentPage.lastPage === true) {
      const buttonProps = {
        type: 'link',
        to: '/e-submission/form/otp',
        text: 'ยืนยันตัวตนลูกค้า',
        disabled: !stateProps.canGotoNextPage,
        onClick: actionProps.verifyPolicy,
      }

      if (getToggles().ENABLE_EKYC_F2F && !isOldRemoteSelling(app)) {
        buttonProps.to = '/e-submission/form/submit'
        buttonProps.text = 'ยืนยัน'
        buttonProps.onClick = actionProps.confirmSubmitted
      }

      nextButtonProps = buttonProps
    } else if (currentPage.isDocumentPage === true && getToggles().ENABLE_TAMLO === true) {
      nextButtonProps = {
        type: 'link',
        to: '/e-submission/form/' + nextPage.id,
        text: nextPage.label,
        disabled: !stateProps.canSign,
      }
    } else if (currentPage.id === 'ekyc') {
      const isOpenInsuredDopaRequestFailedModal =
        (!isNil(stateProps.insuredDopaVerified) && !stateProps.insuredDopaVerified) ||
        stateProps.insuredDopaRequestFailed
      const isOpenPayerDopaRequestFailedModal =
        (!isNil(stateProps.payerDopaVerified) && !stateProps.payerDopaVerified) || stateProps.payerDopaRequestFailed

      const checkOpenInsuredDopaRequestFailed = getToggles().ENABLE_PAY_LATER
        ? isOpenInsuredDopaRequestFailedModal && !stateProps.isSkippedInsuredEkyc
        : stateProps.insuredDopaRequestFailed && !stateProps.isSkippedInsuredEkyc

      const checkOpenPayerDopaRequestFailed = getToggles().ENABLE_PAY_LATER
        ? isOpenPayerDopaRequestFailedModal && !stateProps.isSkippedPayerEkyc
        : stateProps.payerDopaRequestFailed && !stateProps.isSkippedPayerEkyc

      if (checkOpenInsuredDopaRequestFailed) {
        nextButtonProps = {
          type: 'link',
          to: '/e-submission/form/ekyc',
          text: 'ยืนยัน eKYC',
          disabled: false,
          onClick: (insuredOrPayer) => {
            actionProps.openInsuredDopaRequestFailedModal(insuredOrPayer)
            actionProps.dispatchUpdateOptyStageKyc()
          },
        }
      } else if (checkOpenPayerDopaRequestFailed) {
        nextButtonProps = {
          type: 'link',
          to: '/e-submission/form/ekyc',
          text: 'ยืนยัน eKYC',
          disabled: false,
          onClick: (insuredOrPayer) => {
            actionProps.openPayerDopaRequestFailedModal(insuredOrPayer)
            actionProps.dispatchUpdateOptyStageKyc()
          },
        }
      } else {
        nextButtonProps = {
          type: 'link',
          to: '/e-submission/form/' + nextPage.id,
          text: 'ยืนยัน eKYC',
          disabled: isRemoteSelling,
          onClick: () => {
            actionProps.dispatchUpdateOptyStageKyc()
          },
        }
      }
    } else if (currentPage.id === 'lucky-number' && stateProps.isShowLuckyNumber && !stateProps.luckyNumberStatus) {
      nextButtonProps = {
        type: 'link',
        to: '/e-submission/form/' + currentPage.id,
        text: 'ยืนยัน',
        disabled: !stateProps.selectedLuckyNumber,
        onClick: () => {
          actionProps.setConfirmLuckyNumberModal({ modal: true, from: 'normal-assign' })
        },
      }
    } else {
      nextButtonProps = {
        type: 'link',
        to: '/e-submission/form/' + nextPage.id,
        text: nextPage.label,
        disabled: false,
      }
    }
    if (currentPage.remoteSection) {
      nextButtonProps = {
        type: 'link',
        to: '/e-submission/form/' + nextPage.id,
        text: nextPage.label,
        disabled: shouldDisable(currentPage.id, app),
      }
    }
  }

  const navProps = flow(
    reject(['exclude', true]),
    currentPage.remoteSection ? filter(['remoteSection', true]) : reject(['remoteSection', true]),
    map(({ id, title, label, disabled }) => ({
      id,
      title,
      path: `/e-submission/form/${id}`,
      component: null,
      disabled,
    })),
    when(negate(some(({ path }) => endsWith(path, ownProps.match.url))), () => [])
  )(pages)

  return {
    ...stateProps,
    ...actionProps,
    ...ownProps,
    nextButtonProps,
    navProps,
    fetchSignature: ({ currentPageId }) => {
      if (isSubmitPage(currentPageId)) {
        actionProps.fetchSignature()
      }
      if (getToggles().ENABLE_APPMAN_REMOTE_SELLING && isRemoteSelling) {
        if (isEkycPage(currentPageId)) {
          actionProps.openDf2fEkycPage(navProps)
        }
        if (isRemoteSignaturePage(currentPageId)) {
          actionProps.fetchSignature()
        }
      }
    },
  }
}

export default compose(
  getContext({
    setNextButton: PropTypes.func,
    setPages: PropTypes.func,
  }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withProps(({ setPages, setNextButton }) => {
    return {
      setPages: (pages) => {
        if (setPages) {
          setPages(pages)
        }
      },
      setNextButton: (nextButtonProps) => {
        if (setNextButton) {
          setNextButton(isEmpty(nextButtonProps) ? null : nextButtonProps)
        }
      },
    }
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.setPages(null)
      this.props.setNextButton(null)
    },
    componentDidMount() {
      this.props.setPages(this.props.navProps)
      this.props.setNextButton(this.props.nextButtonProps)
      if (getToggles().ENABLE_REMOVE_POLICY_NO_GA4) {
        this.props.dispatchAnalyticsPageViewEvent(this.props.location.pathname)
      }
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!isEqual(nextProps.currentApp, this.props.currentApp)) {
        this.props.setNextButton(nextProps.nextButtonProps)
      }
      if (!isEqual(this.props.navProps, nextProps.navProps)) {
        this.props.setPages(nextProps.navProps)
      }
      if (
        !isEqual(this.props.nextButtonProps.text, nextProps.nextButtonProps.text) ||
        !isEqual(this.props.nextButtonProps.to, nextProps.nextButtonProps.to) ||
        !isEqual(this.props.canGotoNextPage, nextProps.canGotoNextPage) ||
        !isEqual(this.props.canSign, nextProps.canSign)
      ) {
        this.props.setNextButton(nextProps.nextButtonProps)
        this.props.fetchSignature(nextProps)
      }
      const pathname = this.props.location.pathname
      if (this.props.location.pathname !== nextProps.location.pathname) {
        const changePageFromEkyc = (pathname || '').includes('/e-submission/form/ekyc')
        const changePageFrom2Doc = (pathname || '').includes('/e-submission/form/document')
        const changePageOutFromAgent = (pathname || '').includes('/e-submission/form/agent')
        const changePageOutFromInsured = (pathname || '').includes('/e-submission/form/insured')
        const changePageFromInsuredHealth = (pathname || '').includes('/e-submission/form/insured-health')
        const changePageOutFromPayer = (pathname || '').includes('/e-submission/form/owner')
        const changePageToFormEDA = (nextProps.location.pathname || '').includes('/e-submission/form/submit/eda')
        const changePageToFormDA = (nextProps.location.pathname || '').includes('/e-submission/form/submit/atp')
        const changePageOutFromEDAForm = (pathname || '').includes('/e-submission/form/submit/eda')

        if (getToggles().ENABLE_REMOVE_POLICY_NO_GA4) {
          this.props.dispatchAnalyticsPageViewEvent(nextProps.location.pathname)
        }
        if (getToggles().ENABLE_OPTIMIZE_SAVING_REQUEST) {
          this.props.dispatchSavingAppOnPageNav()
        }

        if (
          (changePageOutFromAgent || changePageOutFromInsured || changePageOutFromPayer) &&
          !changePageFromInsuredHealth
        ) {
          // Call update iPro E-APP stage
          // when change out from agent screen, insured info screen and payer info screen (following detail in DIGSALTOOL-2203)
          this.props.dispatchUppdateOptyStageEApp()
        }

        if (changePageFromEkyc || changePageFrom2Doc) {
          // Call update iPro KYC stage
          // when change out from document screen and verify identity screen (following detail in DIGSALTOOL-2203)
          this.props.dispatchUpdateOptyStageKyc()
        }

        if (getToggles().ENABLE_EDA && (changePageToFormEDA || changePageToFormDA)) {
          let typeDA = 'FORM'
          if (changePageToFormEDA) {
            typeDA = 'ONLINE'
            this.props.setDefaultBankForEDA()
          }
          this.props.setTypeDA(typeDA)
        }

        if (getToggles().ENABLE_EDA && changePageOutFromEDAForm) {
          this.props.clearUIFlagEDAViewFormOnly()
          this.props.clearEdaStatusOptions()
        }
      }
    },
  })
)(AppForm)
