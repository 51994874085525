import { connect } from 'react-redux'
import { compose, withProps, withStateHandlers, withHandlers, withState } from 'recompose'
import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter, Button, FormFeedback } from 'reactstrap'

import Icon from 'e-submission/components/Icon'
import Dialog from 'e-submission/components/Dialog'
import { getFileWithName } from 'e-submission/apps/selectors'
import { showSignPad, loadDocument, sendMultipleDocument } from 'e-submission/apps/actions'
import { Tab } from 'common-components'
import { getToggles } from 'quick-quote/feature-toggles'

import viewerUrl from 'file-loader?name=pdf-viewer/web/viewer.html&emitFile=false!e-submission/pdf-viewer/web/viewer.html'

const PdfViewer = styled.iframe`
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`

const onLoadIframe = async (evt, doc) => {
  return new Promise((resolve) => {
    const iframe = evt.target
    const iframeDocument = iframe.contentWindow.document
    const toolbar = iframeDocument.querySelector('.toolbar')
    const content = iframeDocument.querySelector('#viewerContainer')

    toolbar.style.visibility = 'hidden'
    content.style.top = 0

    iframeDocument.body.setAttribute('data-doc-name', doc.name)
    iframeDocument.body.setAttribute('data-readonly', doc.readOnly && doc.readOnly.toString())
    if (getToggles().ENABLE_ECBR_WATERMARK) {
      iframeDocument.body.setAttribute('data-watermark', 'true')
    }
    resolve()
  })
}

const getActionMessage = (document) => {
  return `${PERSON_MAP[document.person]} อ่านเอกสารครบถ้วน สามารถลงนามได้ทันที`
}

const getActionButtonLabel = (document) => {
  const isDocumentChecked = document.checked
  const selectedRemoteSelling = document.selectedRemoteSelling
  return selectedRemoteSelling
    ? 'ส่งเอกสารให้ผู้เอาประกัน'
    : !isDocumentChecked
    ? document.sectionLabel
    : `แก้ไข ${document.sectionLabel}`
}

const isReadAll = (read) => getToggles().SHORTCUT_SINGLE_SIGN || !Object.values(read).includes(false)

const PERSON_MAP = {
  insured: 'ผู้เอาประกันภัย',
  payer: 'ผู้ชำระเบี้ย',
  agent: 'ตัวแทน/นายหน้าประกันชีวิต',
}

export const Component = ({
  toggle,
  className,
  isOpen,
  hideDialog,
  url,
  document,
  onSign,
  handleTabClick,
  onIFrameLoad,
  read,
  signed,
  currentDocIndex,
  currentDocName,
  totalDoc,
  loading,
  selectedRemoteSelling,
  sendCommand,
}) => {
  return (
      <Dialog
          toggle={toggle}
          className={[
            className,
            'az-dialog--preview-multiple-pdf',
            Object.values(read).some((v) => !v) ? '' : 'az-dialog--preview-multiple-pdf--opened-all',
            !signed ? '' : 'az-dialog--preview-multiple-pdf--signed',
          ].join(' ')}
          isOpen={isOpen}
      >
        <ModalHeader>
          <div className="action action--left">
            <div className="btn-left" onClick={hideDialog}>
              <Icon.arrowLeft className="icon" />
            </div>
          </div>
          <div className="title">
            เอกสาร {currentDocIndex + 1} จาก {totalDoc}
          </div>
          <div className="action action--right" />
        </ModalHeader>
        <Tab active={currentDocName} onChange={handleTabClick}>
          {document.allDocuments.map((d) => (
              <Tab.Item id={d.name} key={d.name} className={read[d.name] || document.checked ? 'highlight' : ''}>
          <span className="title-text">
            <span className="icon" />
            {d.shortText || d.text}
          </span>
              </Tab.Item>
          ))}
        </Tab>
        <ModalBody className={`modal-body--pdf-view modal-body--pdf-view--${currentDocName}`}>
          {url && <PdfViewer loading={loading} src={`${viewerUrl}?file=${url}`} onLoad={onIFrameLoad} />}
        </ModalBody>
        {document.readOnly ? null : (
            <ModalFooter>
              {!selectedRemoteSelling && (
                  <div className="form-group">
                    <FormFeedback className="info-message attached-icon">
                      <p>{getActionMessage(document)}</p>
                    </FormFeedback>
                  </div>
              )}
              <Button
                  color={!isReadAll(read) ? 'disabled' : 'primary'}
                  onClick={selectedRemoteSelling ? sendCommand : onSign}
                  disabled={!isReadAll(read)}
              >
                {getActionButtonLabel(document)}
              </Button>
            </ModalFooter>
        )}
      </Dialog>
  )
}

export const hoc = compose(
  withState('currentDocName', 'setCurrentDocName', (props) => props.document.name),
  withStateHandlers(
    (props) => ({
      read:
        props.document.checked ||
        props.document.allDocuments.reduce(
          (map, { name }) => ({
            ...map,
            [name]: name === props.currentDocName,
          }),
          {}
        ),
      signed: props.document.checked,
      loading: false,
    }),
    {
      setRead: (state) => (docName) => ({
        ...state,
        read: { ...state.read, [docName]: true },
      }),
      setLoading: (state) => (isLoading) => ({ ...state, loading: isLoading }),
    }
  ),
  withProps((props) => ({
    currentDoc: props.document.allDocuments.find((d) => d.name === props.currentDocName),
    currentDocIndex: props.document.allDocuments.findIndex((d) => d.name === props.currentDocName),
    totalDoc: props.document.allDocuments.length,
    selectedRemoteSelling: props.document.selectedRemoteSelling,
  })),
  connect(
    (state, { currentDocName }) => ({
      url: getFileWithName(state, currentDocName),
    }),
    (dispatch, { document = {}, currentDoc }) => ({
      onSign: () => dispatch(showSignPad(document)),
      loadDocument: (docName) => dispatch(loadDocument(docName)),
      sendCommand: () => {
        const allDocs = document.allDocuments.map((doc) => {
          const isPdpa = doc.name === 'acknowledge'
          return {
            ...doc,
            policyId: document.policyId,
            needSignature: !isPdpa,
          }
        })
        dispatch(sendMultipleDocument(allDocs, document))
      },
    })
  ),
  withHandlers({
    handleTabClick: (props) => (docName) => {
      props.loadDocument({ ...props.document, name: docName })
      props.setLoading(true)
      setTimeout(() => {
        props.setCurrentDocName(docName)
        props.setRead(docName)
      }, 700)
    },
    onIFrameLoad: (props) => async (e) => {
      await onLoadIframe(e, props.document)
      props.setLoading(false)
    },
  })
)
export default hoc(Component)
