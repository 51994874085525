import {
  getUnsignedDocImg,
  hideDialog,
  previewPhoto,
  resendSignature,
  setAppValue,
  signDocument
} from 'e-submission/apps/actions'
import { getCurrentApp, haveThirdPerson } from 'e-submission/apps/selectors'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import SignCanvas from 'e-submission/containers/Dialog/SignCanvas'
import {
  getFullName,
  getFullNameWithoutPrefix,
  isRemoteSelling,
  getFullNameThirdPerson,
  getFullNameFromSplitThirdPerson,
  getFullNameFromSplitThirdPersonWithoutPrefix,
} from 'e-submission/domain/data-model/form/selectors'
import { getFiles } from 'e-submission/apps/selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import { connect } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Flex } from 'rebass'
import {compose, withState, withHandlers, lifecycle} from 'recompose'
import styled from 'styled-components'
import { isSelectedRemoteSelling, isVideoConsented } from '../../domain/data-model/form/selectors'
import { includes, get } from 'lodash/fp'
import { IsRealDevice } from 'e-submission/utils'

const isPortrait = () => document.documentElement.clientWidth < document.documentElement.clientHeight
const isSubmitDcaPage = () => {
  let currentPath
  if (IsRealDevice) {
    currentPath = window.location.hash
  } else
  {
    currentPath = window.location.pathname
  }
  const dcaPath = 'submit/DCA'
  return currentPath.includes(dcaPath)
}
export const Component = styled(
  ({
    isEmpty,
    toggle,
    setRef,
    className,
    isOpen,
    hideDialog,
    person,
    onClear,
    signDocument,
    isEnableAutoSign,
    isEnableRemoteSellingDcaAutoSign,
    dcaSignedImage,
    setEmpty,
    setSignatureData,
    setSignaturePad,
    selectedRemoteSelling,
    clientSignature,
    clearSignature,
    onReset,
  }) => {
    const _dcaSignedImage = !dcaSignedImage ? null : dcaSignedImage.file
    return (
      <Dialog
        id="signature-dialog"
        toggle={toggle}
        isOpen={isOpen}
        className={className + (isPortrait() ? ' portrait' : '')}
      >
        <ModalHeader>
          <div className="btn-left" onClick={hideDialog}>
            <Icon.arrowLeft className="icon" />
          </div>
        </ModalHeader>
        <ModalBody>
          <Flex align="flex-start" justify="center">
            <label>ลงนาม</label>
            <div className="signaturePad">
              <div className="indicator">
                <div className="description">
                  เซ็นชื่อ
                  <Icon.playArrow />
                </div>
              </div>
              <SignCanvas
                person={person}
                isEnableAutoSign={isEnableAutoSign}
                isEnableRemoteSellingDcaAutoSign={isEnableRemoteSellingDcaAutoSign}
                dcaSignedImage={_dcaSignedImage}
                setEmpty={setEmpty}
                setSignatureData={setSignatureData}
                setSignaturePad={setSignaturePad}
                clientSignature={selectedRemoteSelling && clientSignature}
                clearSignature={clearSignature}
              />
              {/* <div className="canvas-wrapper">
              <canvas ref={setRef} style={{ display: selectedRemoteSelling && clientSignature ? 'none' : '' }} />
              <img
                className="clientSignature"
                src={clientSignature}
                alt="client-signature"
                style={{ display: selectedRemoteSelling && clientSignature ? '' : 'none' }}
              />
            </div> */}
              <Flex align="center" justify="center" column>
                <div>
                  <b>{person.fullName}</b>
                </div>
                <div>{person.text}</div>
                <div>
                  {`ลงนามวันที่ ${new Date().toLocaleDateString('th', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}`}
                </div>
              </Flex>
            </div>
          </Flex>
        </ModalBody>
        <ModalFooter>
          {(!isEnableAutoSign && !isEnableRemoteSellingDcaAutoSign) && !selectedRemoteSelling && (
            <Button color="primary" outline onClick={onClear}>
              เซ็นใหม่
            </Button>
          )}
          {selectedRemoteSelling && (
            <Button color="primary" onClick={onReset} disabled={!isEmpty}>
              เซ็นใหม่
            </Button>
          )}
          <Button
            color={
              (isEmpty && (!isEnableAutoSign && !isEnableRemoteSellingDcaAutoSign) && !selectedRemoteSelling) || (!clientSignature && selectedRemoteSelling)
                ? 'disabled'
                : 'primary'
            }
            onClick={signDocument}
            disabled={selectedRemoteSelling && !clientSignature}
          >
            ตกลง
          </Button>

          {/* {!isEnableAutoSign && (
          <Button color="primary" outline onClick={onClear}>
            เซ็นใหม่
          </Button>
        )}
        <Button color={isEmpty && !isEnableAutoSign ? 'disabled' : 'primary'} onClick={signDocument}>
          ตกลง
        </Button> */}
        </ModalFooter>
      </Dialog>
    )
  }
)`
  .modal-header {
    border: none;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    > div {
      margin-left: -1.5rem;
    }
    label {
      font-weight: bold;
      margin-right: 0.5rem;
    }
    canvas {
      position: fixed;
      border: 1px solid ${({ theme }) => theme.variables['$color-gray--darkest']};
    }
    .signaturePad {
      position: relative;
      .indicator {
        position: absolute;
        display: inline-block;
        padding: 0 12px;
        color: white;
        background: ${({ theme }) => theme.variables['$color-bright-green']};
        transform: rotate(-10deg);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        top: 30px;
        left: -55px;
        z-index: 99;
        .description {
          position: relative;
          font-size: 0.8725rem;
          svg {
            position: absolute;
            top: -8px;
            left: calc(100% - 1px);
            font-size: 1.725rem;
            color: ${({ theme }) => theme.variables['$color-bright-green']};
          }
        }
      }
    }
  }
  .modal-footer {
    border-top: 2px solid ${({ theme }) => theme.variables['$color-bright-green']};
    justify-content: space-around;
    .btn {
      flex: 0 0 25%;
      &.btn-primary {
        border: 2px solid transparent;
      }
    }
  }
`

const getPortraitRatio = () => document.documentElement.clientWidth / document.documentElement.clientHeight
// Duplicate declaration
// const isPortrait = () => document.documentElement.clientWidth < document.documentElement.clientHeight
const getRotation = () => (isPortrait() ? 'portrait' : 'landscape')

let width, height
let prevRotation = getRotation()
/* istanbul ignore next */
const setCanvasSize = (canvas) => {
  const canvasWrapper = document.querySelector('.canvas-wrapper')
  const content = document.querySelector('#signature-dialog .modal-content')

  if (canvas.width === 0 && canvas.height === 0) {
    if (isPortrait()) {
      const windowSize = document.documentElement.clientHeight
      width = windowSize * SCALE
      height = width * RATIO
      canvas.width = height
      canvas.height = width
    } else {
      const windowSize = document.documentElement.clientWidth
      width = windowSize * SCALE
      height = width * RATIO
      canvas.width = width
      canvas.height = height
    }
    canvasWrapper.style.width = width + 'px'
    canvasWrapper.style.height = height + 'px'
  } else {
    if (isPortrait()) {
      canvas.width = height
      canvas.height = width
    } else {
      canvas.width = width
      canvas.height = height
    }
  }

  if (isPortrait()) {
    canvas.style['transform'] = 'rotate(-90deg)'
    canvas.style['transform-origin'] = `50% ${50 * RATIO}%`
  } else {
    canvas.style['transform'] = 'none'
    canvas.style['transform-origin'] = 'unset'
  }

  if (isPortrait()) {
    content.style['transform'] = 'rotate(90deg)'
    content.style['transform-origin'] = `50vw ${50 * getPortraitRatio()}vh`
    content.style['width'] = '100vh'
    content.style['height'] = '100vw'
  } else {
    content.removeAttribute('style')
  }
}

const setImageSize = () => {
  const wrapper = document.querySelector('.clientSignature')
  let width, height
  if (isPortrait()) {
    const windowSize = document.documentElement.clientHeight
    width = windowSize * SCALE
    height = width * RATIO
  } else {
    const windowSize = document.documentElement.clientWidth
    width = windowSize * SCALE
    height = width * RATIO
  }

  wrapper.style.width = width + 'px'
  wrapper.style.height = height + 'px'
}

/* istanbul ignore next */
const toBase64 = (canvas) => {
  const newCanvas = document.createElement('canvas')
  const ctx = newCanvas.getContext('2d')

  const width = canvas.width
  const height = canvas.height
  if (height > width) {
    newCanvas.width = height
    newCanvas.height = width

    ctx.setTransform(
      0,
      -1, // x axis up the screen
      1,
      0, // y axis across the screen from left to right
      0, // x origin is on the left side of the canvas
      width // y origin is at the bottom
    )
  } else {
    newCanvas.width = width
    newCanvas.height = height
  }

  ctx.drawImage(canvas, 0, 0)

  const dataUrl = newCanvas.toDataURL('image/png')
  const base64 = dataUrl.split(',')[1]

  return base64
}

const imageToBase64 = (url, callback) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      var reader = new FileReader()
      reader.onload = function() {
        console.log(reader.result)
        callback(this.result)
      }
      reader.readAsDataURL(blob)
    })
}
/* istanbul ignore next */
// export default compose(
//   connect((state, { document = {} }) => {
//     const app = getCurrentApp(state)
//     const isThirdPerson = haveThirdPerson(app) && document.person == 'thirdPerson'
//     const fullName =
//       isThirdPerson == true
//         ? getToggles().ENABLE_FNA_PHASE2
//           ? getFullNameFromSplitThirdPerson(app)
//           : getFullNameThirdPerson(app)
//         : getFullName(document.person)(app)
//     const fullNameWithoutPrefix =
//       isThirdPerson == true
//         ? getToggles().ENABLE_FNA_PHASE2
//           ? getFullNameFromSplitThirdPersonWithoutPrefix(app)
//           : getFullNameThirdPerson(app)
//         : getFullNameWithoutPrefix(document.person)(app)
//     const isInsuredOrPayerOrThirdPerson = includes(document.person)(['insured', 'payer', 'thirdPerson'])
//     const isEnableAutoSign =
//       isInsuredOrPayerOrThirdPerson && getToggles().ENABLE_AUTO_SIGN_FOR_INSURED_AND_PAYER && isRemoteSelling(app)
//     return {
//       isEnableAutoSign,
//       signature: document.signature,
//       person: {
//         fullName,
//         fullNameWithoutPrefix,
//         text: document.sectionLabel,
//       },
//     }
//   }, null),
//   withState('isEmpty', 'setEmpty', true),
//   withState('signatureData', 'setSignatureData', null),
//   withState('signaturePad', 'setSignaturePad', null),
//   withHandlers(() => {
//     return {
//       onClear: ({ setEmpty, signaturePad }) => () => {
//         setEmpty(true)
//         if (signaturePad) {
//           signaturePad.clear()
//         }
//       },
//     }
//   }),
//   connect(null, (dispatch, { isEnableAutoSign, isEmpty, document = {}, signatureData }) => ({
//     signDocument: () => {
//       if (!isEnableAutoSign && isEmpty) {
//         return
//       }

//       dispatch(
//         signDocument({
//           ...document,
//           data: signatureData,
//         })
//       )
//       dispatch(hideDialog())
//     },
//   }))
// )(Component)

const mapDispatchToProps = (dispatch) => ({
  clearSignature: () => {
    dispatch(setAppValue('clientSignature', null))
  },
})

/* istanbul ignore next */
export default compose(
  connect((state, { document = {} }) => {
    const uiFiles = getFiles(state) || {}
    const app = getCurrentApp(state)
    const isThirdPerson = haveThirdPerson(app) && document.person === 'thirdPerson'
    const fullName =
      isThirdPerson === true
        ? getToggles().ENABLE_FNA_PHASE2
          ? getFullNameFromSplitThirdPerson(app)
          : getFullNameThirdPerson(app)
        : getFullName(document.person)(app)
    const fullNameWithoutPrefix =
      isThirdPerson === true
        ? getToggles().ENABLE_FNA_PHASE2
          ? getFullNameFromSplitThirdPersonWithoutPrefix(app)
          : getFullNameThirdPerson(app)
        : getFullNameWithoutPrefix(document.person)(app)
    const isInsuredOrPayerOrThirdPerson = includes(document.person)(['insured', 'payer', 'thirdPerson'])
    const selectedRemoteSelling = isSelectedRemoteSelling(app) && !isVideoConsented(app)
    const isEnableAutoSign =
      (isInsuredOrPayerOrThirdPerson &&
        getToggles().ENABLE_AUTO_SIGN_FOR_INSURED_AND_PAYER &&
        isRemoteSelling(app) &&
        !selectedRemoteSelling)

    const isEnableRemoteSellingDcaAutoSign = getToggles().ENABLE_APPMAN_REMOTE_SELLING && isInsuredOrPayerOrThirdPerson && isVideoConsented(app) && isSubmitDcaPage()

    const clientSignature = isInsuredOrPayerOrThirdPerson ? get('clientSignature', app) : null
    return {
      isEnableAutoSign,
      isEnableRemoteSellingDcaAutoSign,
      dcaSignedImage: uiFiles.signature_insured,
      signature: document.signature,
      person: {
        fullName,
        fullNameWithoutPrefix,
        text: document.sectionLabel,
      },
      selectedRemoteSelling: selectedRemoteSelling && isInsuredOrPayerOrThirdPerson,
      clientSignature,
    }
  }, null),
  connect(null, mapDispatchToProps),
  withState('isEmpty', 'setEmpty', true),
  withState('signatureData', 'setSignatureData', null),
  withState('signaturePad', 'setSignaturePad', null),
  withHandlers(() => {
    return {
      onClear: ({ setEmpty, signaturePad }) => () => {
        setEmpty(true)
        if (signaturePad) {
          signaturePad.clear()
        }
      },
    }
  }),
  /* istanbul ignore next */
  // lifecycle({
  //   componentDidMount() {
  //     this.props.initSignaturePad()
  //     window.addEventListener('resize', this.props.onResize)

  //     if (this.props.selectedRemoteSelling) {
  //       this.props.getSignaturePad().off()
  //       this.props.initImageSize()
  //     }
  //   },
  //   componentWillUnmount() {
  //     window.removeEventListener('resize', this.props.onResize)
  //     this.props.destroy()
  //     this.props.clearSignature()
  //   },
  // }),

  connect(
    null,
    (dispatch, { isEnableAutoSign, isEnableRemoteSellingDcaAutoSign, isEmpty, document = {}, signatureData, selectedRemoteSelling, onClear }) => ({
      signDocument: () => {
        if (!selectedRemoteSelling && (!isEnableAutoSign && !isEnableRemoteSellingDcaAutoSign) && isEmpty) {
          return
        }
        if (selectedRemoteSelling && !signatureData) return
        dispatch(
          signDocument({
            ...document,
            data: signatureData,
          })
        )
        dispatch(hideDialog())
      },
      onReset: () => {
        onClear()
        dispatch(resendSignature())
      },
      getBiSignedImage: () => {
        dispatch(getUnsignedDocImg('signature_insured'))
      }
    })
  ),
  lifecycle({
      componentDidMount(nextProps) {
        const { getBiSignedImage, isEnableRemoteSellingDcaAutoSign, dcaSignedImage } = this.props
        if (isEnableRemoteSellingDcaAutoSign) getBiSignedImage()
      }
    }
  )
)(Component)
