import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import store from 'e-submission/store'

const CustomDialog = styled(Dialog)`
  ${(props) => {
    const { cssClass } = props
    return `
      .modal-title {
        flex: ${cssClass === 'modal-title-wide' ? '0 0 93%' : '1 0 auto'};
        font-weight: bold;
        text-align: center;
        color: ${({ theme }) => theme.variables['$brand-primary']};
      }
      .modal-body {
        padding: 60px 5px;
        text-align: center;
      }
      .info {
        font-weight: bold;
        color: ${({ theme }) => theme.variables['$brand-primary']};
      }
      .modal-footer {
        justify-content: space-between;
        &.center {
          justify-content: center;
        }
      }
    `
  }}
`

const Confirmation = ({
  toggle,
  isOpen,
  className,
  confirmYes,
  confirmYesWithAction,
  confirmNo,
  message: { title = '', body = '', cssClass = '' },
  onlyConfirm,
  confirmText,
}) => (
  <CustomDialog
    toggle={toggle}
    className={className}
    modalClassName="default-bootstrap"
    isOpen={isOpen}
    backdrop={true}
    cssClass={cssClass}
  >
    <ModalHeader tag="div" toggle={toggle}>
      {title}
    </ModalHeader>
    <ModalBody dangerouslySetInnerHTML={{ __html: body }} />
    <ModalFooter className={onlyConfirm ? 'center' : ''}>
      {onlyConfirm ? null : (
        <Button color="primary" outline onClick={confirmNo}>
          ยกเลิก
        </Button>
      )}
      <Button
        color="primary"
        onClick={() => {
          if (confirmYesWithAction) {
            store.dispatch({
              type: confirmYesWithAction,
            })
          }
          confirmYes()
        }}
      >
        {confirmText ? confirmText : 'ยืนยัน'}
      </Button>
    </ModalFooter>
  </CustomDialog>
)

export default Confirmation
