import { d } from 'core/service/lib/decimal'
import { map } from 'lodash/fp'
import { useEffect } from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import SignaturePad from 'signature_pad'

const getPortraitRatio = () => document.documentElement.clientWidth / document.documentElement.clientHeight
const isPortrait = () => document.documentElement.clientWidth < document.documentElement.clientHeight
const getRotation = () => (isPortrait() ? 'portrait' : 'landscape')

const SCALE = 0.7
const RATIO = 30 / 100

let width, height
let prevRotation = getRotation()
/* istanbul ignore next */
const setCanvasSize = (canvas) => {
  const canvasWrapper = document.querySelector('.canvas-wrapper')
  const modalDialog = document.querySelector('.modal-dialog[class*="Signature__"]') // select only signature modal dialog

  if (modalDialog) {
    modalDialog.setAttribute('id', 'signature-dialog')
  }

  const content = document.querySelector('#signature-dialog .modal-content')

  if (canvas.width === 0 && canvas.height === 0) {
    if (isPortrait()) {
      const windowSize = document.documentElement.clientHeight
      width = windowSize * SCALE
      height = width * RATIO
      canvas.width = height
      canvas.height = width
    } else {
      const windowSize = document.documentElement.clientWidth
      width = windowSize * SCALE
      height = width * RATIO
      canvas.width = width
      canvas.height = height
    }
    canvasWrapper.style.width = width + 'px'
    canvasWrapper.style.height = height + 'px'
  } else {
    if (isPortrait()) {
      canvas.width = height
      canvas.height = width
    } else {
      canvas.width = width
      canvas.height = height
    }
  }

  if (isPortrait()) {
    canvas.style['transform'] = 'rotate(-90deg)'
    canvas.style['transform-origin'] = `50% ${50 * RATIO}%`
  } else {
    canvas.style['transform'] = 'none'
    canvas.style['transform-origin'] = 'unset'
  }

  if (isPortrait()) {
    content.style['transform'] = 'rotate(90deg)'
    content.style['transform-origin'] = `50vw ${50 * getPortraitRatio()}vh`
    content.style['width'] = '100vh'
    content.style['height'] = '100vw'
  } else {
    content.removeAttribute('style')
  }
}

/* istanbul ignore next */
const toBase64 = (canvas) => {
  const newCanvas = document.createElement('canvas')
  const ctx = newCanvas.getContext('2d')

  const width = canvas.width
  const height = canvas.height
  if (height > width) {
    newCanvas.width = height
    newCanvas.height = width

    ctx.setTransform(
      0,
      -1, // x axis up the screen
      1,
      0, // y axis across the screen from left to right
      0, // x origin is on the left side of the canvas
      width // y origin is at the bottom
    )
  } else {
    newCanvas.width = width
    newCanvas.height = height
  }

  ctx.drawImage(canvas, 0, 0)

  const dataUrl = newCanvas.toDataURL('image/png')
  const base64 = dataUrl.split(',')[1]

  return base64
}

/* istanbul ignore next */
const checkEmpty = (signaturePad) => {
  if (signaturePad) {
    return signaturePad.isEmpty()
  }
  return true
}

/* istanbul ignore next */
const rotateSnapshot = (canvas) =>
  map(
    map(({ x, y, ...data }) => {
      if (isPortrait()) {
        return {
          ...data,
          x: d(canvas.width)
            .minus(y)
            .toNumber(),
          y: x,
        }
      }
      return {
        ...data,
        x: y,
        y: d(canvas.height)
          .minus(x)
          .toNumber(),
      }
    })
  )

const SignCanvas = ({ setRef, clientSignature, setSignatureData, dcaSignedImage, isEnableRemoteSellingDcaAutoSign, ...rest }) => {

  const rotateImage = (image, isPortrait) => {
    const tempCanvas = document.createElement('canvas')
    const tempCtx = tempCanvas.getContext('2d')
    const { width, height } = image

    if (isPortrait) {
      tempCanvas.width = height
      tempCanvas.height = width
      tempCtx.translate(height / 2, width / 2)
      tempCtx.rotate(Math.PI / 2)
      tempCtx.drawImage(image, -width / 2, -height / 2)
    } else {
      tempCanvas.width = width
      tempCanvas.height = height
      tempCtx.drawImage(image, 0, 0)
    }

    return tempCanvas
  }
  const drawImage = (rotatedImageCanvas, signatureCanvas) => {
    const ctx = signatureCanvas.getContext('2d')
    const imgWidth = rotatedImageCanvas.width
    const imgHeight = rotatedImageCanvas.height
    const canvasWidth = signatureCanvas.width
    const canvasHeight = signatureCanvas.height

    // Maintain aspect ratio
    const aspectRatio = imgWidth / imgHeight
    let newWidth = canvasWidth
    let newHeight = canvasWidth / aspectRatio
    if (newHeight > canvasHeight) {
      newHeight = canvasHeight
      newWidth = canvasHeight * aspectRatio
    }

    const xOffset = (canvasWidth - newWidth) / 2
    const yOffset = (canvasHeight - newHeight) / 2

    ctx.clearRect(0, 0, canvasWidth, canvasHeight)
    ctx.drawImage(rotatedImageCanvas, xOffset, yOffset, newWidth, newHeight)
    ctx.save()
    setSignatureData(toBase64(signatureCanvas))
  }

  useEffect(() => {
    if (!dcaSignedImage || !isEnableRemoteSellingDcaAutoSign) return
    const canvas = document.getElementById('signature-canvas')
    const img = new Image();
    img.src = dcaSignedImage
    img.onload = () => {
      const rotatedImageCanvas = rotateImage(img, isPortrait())
      drawImage(rotatedImageCanvas, canvas)
    };
  }, [dcaSignedImage, isEnableRemoteSellingDcaAutoSign])

  useEffect(() => {
    if (clientSignature && !isEnableRemoteSellingDcaAutoSign) {
      const canvas = document.getElementById('signature-canvas')
      if (!canvas) return
      const image = new Image()
      image.src = clientSignature
      image.crossOrigin = 'anonymous'
      image.onload = function() {
        const rotatedImageCanvas = rotateImage(image, isPortrait())
        drawImage(rotatedImageCanvas, canvas)
      }
    }
  }, [clientSignature])

  return (
    <div
      className="canvas-wrapper"
      style={{
        pointerEvents: clientSignature && 'none',
      }}
    >
      <canvas ref={setRef} id="signature-canvas" />
    </div>
  )
}

export default compose(
  withHandlers(() => ({
    fillMockSignature: ({ person, setSignatureData }) => (canvas) => {
      const ctx = canvas.getContext('2d')
      let text = person.fullNameWithoutPrefix
      if (isPortrait()) {
        ctx.rotate((90 * Math.PI) / 180)
      }
      let fontSize = 300
      const canvasWidth = (isPortrait() ? canvas.height : canvas.width) - 20 // add a little padding
      const canvasHeight = (isPortrait() ? canvas.width : canvas.height) - 20 // add a little padding
      do {
        fontSize--
        ctx.font = fontSize + 'px helvethaica'
      } while (
        ctx.measureText(text).width > canvasWidth - 50 ||
        ctx.measureText(text).actualBoundingBoxAscent > canvasHeight - 50
      )
      const posX = canvasWidth / 2 - ctx.measureText(text).width / 2
      const posY = (canvasHeight / 2 + 60) * (isPortrait() ? -0.3 : 1)
      ctx.fillText(text, posX, posY)
      ctx.save()
      setSignatureData(toBase64(canvas))
    },
  })),
  withHandlers((props) => {
    let canvas = props
    let signaturePad = null
    /* istanbul ignore next */
    return {
      setRef: () => (ref) => {
        canvas = ref
      },
      initSignaturePad: ({ setEmpty, setSignatureData, setSignaturePad, isEnableRemoteSellingDcaAutoSign }) => () => {
        canvas.width = 0
        canvas.height = 0
        setCanvasSize(canvas)
        if (props.isEnableAutoSign) {
          props.fillMockSignature(canvas)
        } else {
          signaturePad = new SignaturePad(canvas, {
            // backgroundColor: 'rgba(255,255, 255, 0)',
            onEnd: () => {
              setEmpty(checkEmpty(signaturePad))
              setSignatureData(toBase64(canvas))
            },
          })
          setSignaturePad(signaturePad)
        }
      },
      getSignaturePad: () => () => signaturePad,
      onResize: ({ clearSignature, clientSignature }) => () => {
        const currentRotation = getRotation()
        if (currentRotation !== prevRotation) {
          setCanvasSize(canvas)
          if (signaturePad) {
            const snapshot = signaturePad.toData()
            signaturePad.clear()
            if (clientSignature) clearSignature()
            signaturePad.fromData(rotateSnapshot(canvas)(snapshot))
          }
          if (props.isEnableAutoSign) {
            props.fillMockSignature(canvas)
          }
          prevRotation = currentRotation
        }
      },
      destroy: () => () => {
        if (signaturePad) {
          signaturePad.off()
        }
        signaturePad = null
        canvas = null
      },
    }
  }),
  /* istanbul ignore next */
  lifecycle({
    componentDidMount() {
      this.props.initSignaturePad()
      window.addEventListener('resize', this.props.onResize)
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.onResize)
      this.props.destroy()
    },
  })
)(SignCanvas)
