import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import { formatAccountNumber } from 'e-submission/utils/tranformData'
import store from 'e-submission/store'

const CustomDialog = styled(Dialog)`
  ${(props) => {
    const { cssClass, theme } = props
    return `
      .modal-title {
        flex: ${cssClass === 'modal-title-wide' ? '0 0 93%' : '1 0 auto'};
        font-weight: bold;
        color: ${theme.variables['$brand-primary']};
        text-align: center;
      }
     .modal-body {
        text-align: center;
        padding: 25px 40px;
        background: white;
      .detail {
        display:flex;
        padding: 2px;
      }
      .section {
        padding: 15px 0px 0px;
      }
      .bold {
      font-weight: bold;
      padding-left: 5px;
      }
    }
    .modal-footer {
       border-top: none;
       justify-content: center;
   }
  `
  }}
`

const ConfirmationCheckAcc = ({
  toggle,
  isOpen,
  className,
  confirmYes,
  confirmNo,
  message: { title = '', body = '', cssClass = '' },
  onlyConfirm,
  accDetail,
  confirmText,
  cancelText,
  confirmYesWithAction,
  confirmYesWithData,
  confirmNoWithAction,
  confirmNoWithData,
}) => (
  <CustomDialog
    toggle={toggle}
    className={className}
    modalClassName="default-bootstrap"
    isOpen={isOpen}
    backdrop={true}
    cssClass={cssClass}
  >
    <ModalHeader tag="div" toggle={toggle}>
      {title}
    </ModalHeader>
    <ModalBody>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
      {accDetail && (
        <>
          <div className="detail section">
            <div>ธนาคาร : </div>
            <div className="bold">{accDetail?.bankName}</div>
          </div>
          <div className="detail">
            <div>ชื่อบัญชี : </div>
            <div className="bold">{accDetail?.accountName}</div>
          </div>
          <div className="detail">
            <div>เลขบัญชี : </div>
            <div className="bold">{formatAccountNumber(accDetail?.accountNo)}</div>
          </div>
        </>
      )}
    </ModalBody>
    <ModalFooter>
      {onlyConfirm ? null : (
        <Button
          color="primary"
          outline
          onClick={() => {
            if (confirmNoWithAction) {
              store.dispatch({
                type: confirmNoWithAction,
                payload: confirmNoWithData,
              })
            }
            confirmNo()
          }}
        >
          {cancelText ? cancelText : 'ยกเลิก'}
        </Button>
      )}
      <Button
        color="primary"
        onClick={() => {
          if (confirmYesWithAction) {
            store.dispatch({
              type: confirmYesWithAction,
              payload: confirmYesWithData,
            })
          }
          confirmYes()
        }}
      >
        {confirmText ? confirmText : 'ยืนยัน'}
      </Button>
    </ModalFooter>
  </CustomDialog>
)

export default ConfirmationCheckAcc
